<template>
  <moe-page title="猜你喜欢商品池">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="font-20 fwb">猜你喜欢商品池</div>
        <div class="font-14">猜你喜欢将在搜索结果页底部、购物车页面底部展示。</div>
      </template>
      <moe-form
        class="default-form"
        ref="guessForm"
        :showBack="false"
        :model="guessParams"
        :rules="rules">
        <el-form-item label="排序方式" prop="sortType">
          <div class="df aic">
            <moe-radio v-model="guessParams.sortType" label="AI">智能排序</moe-radio>
            <div class="font-14 wsn">池内商品将会根据固定算法推荐给用户。规则如下：优先排序该用户查看次数最多的商品。其次排序总浏览量最多的商品。</div>
          </div>
          <div>
            <moe-radio v-model="guessParams.sortType" label="MANUAL">手动排序</moe-radio>
          </div>
        </el-form-item>
      </moe-form>
      <moe-table :data="guessParams.itemList" :numberShow="false" :showPage="false" :mode="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="showDialog = true;">添加商品</el-button>
        <el-table-column label="商品信息" min-width="200">
          <template slot-scope="{ row }">
            <div class="df aic jcc">
              <moe-image :src="row.coverUrl" width="80px" height="80px" />
              <div class="df1 tal ml-10">
                <p class="fwb">{{ row.name }}</p>
                <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="销售价" min-width="150" :formatter="formatterSalePrice" />
        <el-table-column prop="stock" label="库存" min-width="80" />
        <el-table-column label="操作" min-width="350" fixed="right">
          <div class="moe-table_btns df aic jcc" slot-scope="{ row, $index }">
            <template v-if="$index > 0">
              <el-button type="success" size="mini" icon="el-icon-upload2" @click="handleClick('topping', row, $index)">置顶</el-button>
              <el-button type="primary" size="mini" icon="el-icon-top" @click="handleClick('top', row, $index)">上移</el-button>
            </template>
            <el-button v-if="$index < (guessParams.itemList.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleClick('bottom', row, $index)">下移</el-button>
            <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleClick('delete', row, $index)">移除</el-button>
          </div>
        </el-table-column>
      </moe-table>
      <div class="df aic jcc mt-20">
        <el-button type="primary" icon="el-icon-upload2" :loading="guessLoad" @click="req_updateGoodsGuessList()">{{ guessLoad ? '保存中...' : '提交' }}</el-button>
      </div>
    </moe-card>

    <goodsDialog
      :showDialog.sync="showDialog"
      :defaultSelectIds="guessParams.itemList && guessParams.itemList.length ? guessParams.itemList.map(({ goodsId }) => goodsId) : []"
      @close="handleClose">
    </goodsDialog>
  </moe-page>
</template>

<script>
import goodsDialog from '@/views/ActivityManage/components/goodsDialog.vue';
export default {
  name: 'ActivityManageGuessList',
  components: {
    goodsDialog
  },
  data() {
    const verify = this.$moe_verify.verifyForm;
    return {
      guessParams: {
        sortType: '',
        itemList: [],
      },
      showDialog: false,
      guessLoad: false,
      rules: {
        sortType: verify.isEmpty('请选择排序方式'),
      }
    }
  },
  methods: {
    formatterSalePrice({ minPrice, maxPrice }) {
      return `¥ ${minPrice}${this.$moe_math.mathGreaterThan(maxPrice, minPrice) ? ` - ¥ ${maxPrice}` : ''}`
    },
    handleClose(selectList) {
      selectList.forEach((item) => {
        let findItem = this.guessParams.itemList.find(({ goodsId }) => item.id === goodsId);
        if (!findItem) {
          this.guessParams.itemList.push({
            ...item,
            goodsId: item.id,
            id: ''
          });
        }
      })

      this.showDialog = false;
    },
    handleClick(type, { name }, index) {
      let { itemList } = this.guessParams;
      switch (type) {
        case 'delete':
          this.$moe_layer.confirm(`您确定要移除当前选项"${name}"吗？`, () => {
            itemList.splice(index, 1);
            this.$moe_msg.success('移除成功,提交后更新');
          });
          break;
        case 'topping':
          this.$moe_layer.confirm(`您确定要置顶当前选项"${name}"吗？`, () => {
            const row = this.$moe_lodash.cloneDeepData(itemList[index]);
            itemList.splice(index, 1);
            itemList.unshift(row);
            this.$moe_msg.success('置顶成功,提交后更新');
          });
          break;
        case 'top':
          this.$moe_layer.confirm(`您确定要上移当前选项"${name}"吗？`, () => {
            itemList.splice(index - 1, 2, itemList[index], itemList[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':
          this.$moe_layer.confirm(`您确定要下移当前选项"${name}"吗？`, () => {
            itemList.splice(index, 2, itemList[index + 1], itemList[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
      }
    },
    /** 获取猜你喜欢信息 */
    async req_getGoodsGuessList() {
      const { goodsId } = this.guessParams;
      const { code, result, message } = await this.$moe_api.GUESS_API.getGoodsGuessList({ goodsId });
      if (code === 200) {
        if (result.itemList.length) {
          this.req_getQueryShopGoodsByIds(result.itemList);
        }
        this.guessParams.sortType = result.sortType;
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 根据商品ID获取商品列表 */
    async req_getQueryShopGoodsByIds(goodsList) {
      const { code, result, message } = await this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: goodsList.map(({ goodsId }) => goodsId).join(',') })
      if (code === 200) {
        this.guessParams.itemList = goodsList.map(({ id, goodsId, sort }) => {
          let findItem = result.find(({ id }) => goodsId === id);
          return {
            ...findItem,
            id,
            goodsId,
            sort,
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 更新猜你喜欢信息 */
    async req_updateGoodsGuessList() {
      this.goodsRecommendLoad = true;
      let arrayLength = this.guessParams.itemList.length - 1;
      const { code, message } = await this.$moe_api.GUESS_API.updateGoodsGuessList({
        ...this.guessParams,
        itemList: this.guessParams.itemList.map(({ id, goodsId }, index) => {
          return {
            id: id || '',
            goodsId,
            sort: arrayLength - index,
          }
        })
      });
      if (code === 200) {
        this.$moe_msg.success('操作成功');
      } else {
        this.$moe_msg.error(message);
      }
      this.goodsRecommendLoad = false;
    },
  },
  mounted() {
    this.req_getGoodsGuessList();
  }
}
</script>